import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";

import enMessages from '../../i18n/legal/listInsiderInfo/en.js'
import ruMessages from '../../i18n/legal/listInsiderInfo/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const ListInsiderInfoPage = ({ pathContext: { locale } }) => {
    const section = 'listInsiderInfo';

    const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
        if (locale === 'en'){
            window.location.replace('/en/legal/requisite');
        }
    }

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            {messages[locale].content.map(item => (
              <RectangleLink
                title={item.title}
                label={item.label}
                link={item.link}
                target="_blank"
              />
            ))}
        </TemplateLegalPage>
    )
};

export default ListInsiderInfoPage;
